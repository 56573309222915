<template>
  <component
    :is="tag"
    :class="[rowGapClasses[rowGap]]"
    class="blueprint-grid"
    data-testid="gridcomponent"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import type { GridProps } from "./types";

withDefaults(defineProps<GridProps>(), {
  tag: "section",
  rowGap: "none",
});

const rowGapClasses = {
  ["none"]: "ui-gap-y-none",
  ["3xs"]: "ui-gap-y-3xs",
  ["2xs"]: "ui-gap-y-2xs",
  ["xs"]: "ui-gap-y-xs",
  ["sm"]: "ui-gap-y-sm",
  ["md"]: "ui-gap-y-md",
  ["lg"]: "ui-gap-y-lg",
  ["xl"]: "ui-gap-y-xl",
  ["2xl"]: "ui-gap-y-2xl",
  ["3xl"]: "ui-gap-y-3xl",
};
</script>
