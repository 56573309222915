<template>
  <component
    :is="tag"
    :class="[
      gridSpanClasses[colSpan],
      gridStartClasses[colStart],
      gridEndClasses[colEnd],
      responsiveClasses && responsiveClasses,
    ]"
  >
    <slot />
  </component>
</template>

<script lang="ts" setup>
import type { GridItemProps } from "./types";
import { computed } from "vue";

const props = withDefaults(defineProps<GridItemProps>(), {
  tag: "section",
  colSpan: "auto",
  colStart: "unset",
  colEnd: "unset",
});

const responsiveClasses = computed(() => {
  return Object.entries(props.breakpoints).map(([breakpoint, spanValue]) => {
    return `${breakpoint}:ui-col-span-${spanValue}`;
  });
});

const gridSpanClasses = {
  ["unset"]: "ui-col-span-unset",
  ["auto"]: "ui-col-span-auto",
  ["1"]: "ui-col-span-1",
  ["2"]: "ui-col-span-2",
  ["3"]: "ui-col-span-3",
  ["4"]: "ui-col-span-4",
  ["5"]: "ui-col-span-5",
  ["6"]: "ui-col-span-6",
  ["7"]: "ui-col-span-7",
  ["8"]: "ui-col-span-8",
  ["9"]: "ui-col-span-9",
  ["10"]: "ui-col-span-10",
  ["11"]: "ui-col-span-11",
  ["12"]: "ui-col-span-12",
};

const gridStartClasses = {
  ["unset"]: "ui-col-start-unset",
  ["auto"]: "ui-col-start-auto",
  ["1"]: "ui-col-start-1",
  ["2"]: "ui-col-start-2",
  ["3"]: "ui-col-start-3",
  ["4"]: "ui-col-start-4",
  ["5"]: "ui-col-start-5",
  ["6"]: "ui-col-start-6",
  ["7"]: "ui-col-start-7",
  ["8"]: "ui-col-start-8",
  ["9"]: "ui-col-start-9",
  ["10"]: "ui-col-start-10",
  ["11"]: "ui-col-start-11",
  ["12"]: "ui-col-start-12",
};

const gridEndClasses = {
  ["unset"]: "ui-col-end-unset",
  ["auto"]: "ui-col-end-auto",
  ["1"]: "ui-col-end-1",
  ["2"]: "ui-col-end-2",
  ["3"]: "ui-col-end-3",
  ["4"]: "ui-col-end-4",
  ["5"]: "ui-col-end-5",
  ["6"]: "ui-col-end-6",
  ["7"]: "ui-col-end-7",
  ["8"]: "ui-col-end-8",
  ["9"]: "ui-col-end-9",
  ["10"]: "ui-col-end-10",
  ["11"]: "ui-col-end-11",
  ["12"]: "ui-col-end-12",
};
</script>
