const useSortBy = () => {
  const route = useRoute();
  const router = useRouter();
  const sortTerm = ref(
    route.query.sort_by === undefined || route.query.sort_by === null
      ? ""
      : decodeURIComponent(route.query.sort_by as string),
  );

  const sortBy = async (isVatIncluded: boolean, value: string) => {
    const sortCriteria = isVatIncluded ? "price_inc_vat" : "price";
    const { path, query } = route;
    const { sort_by, ...rest } = query;

    sortTerm.value = value === "default" ? "" : value === "price_asc" ? `${sortCriteria}` : `-${sortCriteria}`;

    router.push({
      path,
      query: {
        ...rest,
        sort_by: sortTerm.value,
        sort_value: value,
      },
    });
  };

  return {
    sortTerm,
    sortBy,
  };
};

export default useSortBy;
